import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps } from '../../utils/utils';
import { MAIN_COLOR_STRING } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { MyTextFiled } from '../UI/MyTextField';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { ServerStateDto } from '../../dto/ServerStateDto';
import { apiUpdateServerState } from '../../service/server-state/apiUpdateServerState';
import { MyButton } from '../UI/MyButton';

interface Props {
  open: boolean;
  dto: ServerStateDto;
  onClose: () => void;
}

export const DialogServerStateIp: FunctionComponent<Props> = ({
  open,
  dto,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [serverStateDto, setServerStateDto] = useState<ServerStateDto>({
    id: dto.id,
    serverId: '',
    organization: dto.organization,
    name: '',
    appVer: '',
    baseVer: '',
    dateconnect: -1,
    ipLocal: dto.ipLocal,
    note: dto.note,
    password: '',
    resDate: '',
    resState: '',
    dateFrom: -1,
    dateTo: -1,
    connectResult: -1,
    baseResult: -1,
  });
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [backDrop, setBackDrop] = useState(false);

  const [checkHelper, setCheckHelper] = useState(false);

  const handleClose = () => {
    onClose();
    setServerStateDto({
      id: -1,
      serverId: '',
      name: '',
      organization: '',
      appVer: '',
      baseVer: '',
      dateconnect: -1,
      ipLocal: '',
      note: '',
      password: '',
      resDate: '',
      resState: '',
      dateFrom: -1,
      dateTo: -1,
      connectResult: -1,
      baseResult: -1,
    });
    setCheckHelper(false);
  };

  const updateServerState = async () => {
    const apiResult: IApiResult = await apiUpdateServerState(serverStateDto);
    if (apiResult.isSuccess) {
      handleClose();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handelCreate = async () => {
    await updateServerState();
  };

  useEffect(() => {
    if (open) {
      setServerStateDto(dto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv
            title={t('headerServerIpLink')}
            onClose={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <Stack spacing={1}>
            <MyTextFiled
              value={serverStateDto?.organization}
              autoComplete="off"
              onChange={(e) =>
                setServerStateDto({
                  ...serverStateDto,
                  organization: e.target.value,
                })
              }
              disabled
              size="small"
              error={checkHelper}
              label={t('headerServerOrganization')}
            />
            <MyTextFiled
              value={serverStateDto?.ipLocal}
              autoComplete="off"
              onChange={(e) =>
                setServerStateDto({
                  ...serverStateDto,
                  ipLocal: e.target.value,
                })
              }
              disabled
              size="small"
              error={checkHelper}
              label={t('headerServerIpLocal')}
            />
            <MyTextFiled
              value={serverStateDto?.note}
              onChange={(e) =>
                setServerStateDto({ ...serverStateDto, note: e.target.value })
              }
              multiLine
              autoComplete="off"
              size="small"
              error={checkHelper}
              label={t('headerServerNote')}
            />
            <MyButton
              text={t('save')}
              background={MAIN_COLOR_STRING}
              onClick={handelCreate}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
