import { Card, Divider, Grid, Stack, SvgIcon, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BATTERY_ROUTE,
  CLOUD_USER_ROUTE,
  DEVICE_PASSWORDS_ROUTE,
  FACTORY_TOKEN_ROUTE,
  LICENSE_RESULT_ROUTE,
  LOCAL_SERVER_ACTIVATE_ROUTE,
  LOCAL_SERVER_STATE_ROUTE,
  SERVICE_TOKEN_ROUTE,
} from '../../constant/routes';
import { getFactoryStageDescription, getMainGridSize } from '../../utils/utils';
import { FactoryStageEnum } from '../../enum/FactoryStageEnum';

interface Props {
  param: number;
  color: string;
  value?: number;
  image?: any;
}

export const FactoryDivGridItem: FunctionComponent<Props> = ({
  param,
  color,
  value,
  image,
}) => {
  const navigate = useNavigate();
  const handleClick = (num: number) => {
    if (param === FactoryStageEnum.SERVICE_TOKEN) {
      localStorage.setItem(
        SERVICE_TOKEN_ROUTE,
        getFactoryStageDescription(num)
      );
      navigate(SERVICE_TOKEN_ROUTE);
    }
    if (param === FactoryStageEnum.PASSWORDS) {
      localStorage.setItem(
        DEVICE_PASSWORDS_ROUTE,
        getFactoryStageDescription(num)
      );
      navigate(DEVICE_PASSWORDS_ROUTE);
    }
    if (param === FactoryStageEnum.BATTERY_ACTIVATE) {
      localStorage.setItem(BATTERY_ROUTE, getFactoryStageDescription(num));
      navigate(BATTERY_ROUTE);
    }
    if (param === FactoryStageEnum.FACTORY_TOKEN) {
      localStorage.setItem(BATTERY_ROUTE, getFactoryStageDescription(num));
      navigate(FACTORY_TOKEN_ROUTE);
    }
    if (param === FactoryStageEnum.LOCAL_SERVER_ACTIVATE) {
      localStorage.setItem(
        LOCAL_SERVER_ACTIVATE_ROUTE,
        getFactoryStageDescription(num)
      );
      navigate(LOCAL_SERVER_ACTIVATE_ROUTE);
    }
    if (param === FactoryStageEnum.LOCAL_SERVER_STATE) {
      localStorage.setItem(
        LOCAL_SERVER_STATE_ROUTE,
        getFactoryStageDescription(num)
      );
      navigate(LOCAL_SERVER_STATE_ROUTE);
    }
    if (param === FactoryStageEnum.LICENSE_RESULT) {
      localStorage.setItem(
        LICENSE_RESULT_ROUTE,
        getFactoryStageDescription(num)
      );
      navigate(LICENSE_RESULT_ROUTE);
    }
    if (param === FactoryStageEnum.CLOUD_USER) {
      localStorage.setItem(CLOUD_USER_ROUTE, getFactoryStageDescription(num));
      navigate(CLOUD_USER_ROUTE);
    }
  };
  return (
    <Grid item xs={getMainGridSize()}>
      <Card
        style={{
          margin: '5px',
          background: '#ffffff',
          cursor: 'pointer',
        }}
        onClick={() => handleClick(param)}
      >
        <Stack padding="20px" justifyContent="space-between" direction="row">
          <Stack
            direction="column"
            width="100%"
            alignItems="flex-start"
            spacing={2}
          >
            <Stack
              direction="row"
              width="100%"
              height="100px"
              alignItems="center"
              justifyContent="space-between"
            >
              <SvgIcon
                sx={{
                  fontSize: 60,
                  color,
                }}
                component={image}
              />
              {value !== undefined && (
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '50px',
                    color: '#000000',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {value}
                </Typography>
              )}
            </Stack>
            <Divider style={{ width: '100%' }} />
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '20px',
                color: '#000000',
                letterSpacing: 1,
              }}
              component="text"
            >
              {getFactoryStageDescription(param)}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};

FactoryDivGridItem.defaultProps = {
  value: undefined,
  image: undefined,
};
