import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { Box, createTheme, Stack, ThemeProvider } from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  PAGE_SIZE_DEVICES,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { DevicePasswordDto } from '../../dto/DevicePasswordDto';
import { apiGetDevicePasswords } from '../../service/localbase/apiGetDevicePasswords';

export const DevicePasswordsDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [devicePasswordDtos, setDevicePasswordDtos] = useState<
    DevicePasswordDto[]
  >([]);

  const getDevicePasswords = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetDevicePasswords();
    if (apiResult.isSuccess) {
      setDevicePasswordDtos(apiResult.data.passwords);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getDevicePasswords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnsPass: GridColDef[] = [
    {
      field: 'deviceNum',
      headerName: t('headerDevicePassowrdsDeviceNum'),
      renderHeader: () => (
        <strong>{t('headerDevicePassowrdsDeviceNum')}</strong>
      ),
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.deviceNum,
    },
    {
      field: 'password',
      headerName: t('headerDevicePassowrdsPassword'),
      renderHeader: () => <strong>{t('headerDevicePassowrdsPassword')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.password,
    },
    {
      field: 'imei',
      headerName: t('headerDevicePassowrdsImei'),
      renderHeader: () => <strong>{t('headerDevicePassowrdsImei')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.imei,
    },
    {
      field: 'changedDate',
      headerName: t('headerDevicePassowrdsChangedDate'),
      renderHeader: () => (
        <strong>{t('headerDevicePassowrdsChangedDate')}</strong>
      ),
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.changedDate),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('devicePasswords')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columnsPass}
                rows={devicePasswordDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
