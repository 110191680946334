import React from 'react';
import { MainPageLayout } from '../layout/MainPageLayout';
import { FactoryNavigationBar } from '../component/navigation-bar/FactoryNavigationBar';
import { LocalServerStateDiv } from '../component/local-server-state/LocalServerStateDiv';

export const LocalServerStatePage = () => (
  <MainPageLayout
    navigationBar={<FactoryNavigationBar />}
    elementCentr={<LocalServerStateDiv />}
  />
);
