export enum FactoryStageEnum {
  SERVICE_TOKEN = 0,
  PASSWORDS = 1,
  BATTERY_ACTIVATE = 2,
  FACTORY_TOKEN = 3,
  LOCAL_SERVER_ACTIVATE = 4,
  LICENSE_RESULT = 5,
  CLOUD_USER = 6,
  LOCAL_SERVER_STATE = 7,
}
