import { Card, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { FunctionComponent } from 'react';
import { FULLNAME_NAME } from '../../constant/constants';

interface Props {
  title: string;
}

export const FactoryBodyHeaderDiv: FunctionComponent<Props> = ({ title }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { t } = useTranslation();

  return (
    <div>
      <Stack spacing={2}>
        <Card
          style={{
            padding: '15px 25px 15px 25px',
            marginTop: '20px',
            background: '#9e9e9e',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            width="100%"
            justifyContent="space-between"
          >
            <Stack
              direction="row"
              alignItems="center"
              width="100%"
              justifyContent="space-between"
              spacing={2}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '24px',
                    color: '#FFFFFF',
                    letterSpacing: 1,
                  }}
                  component="text"
                >
                  {title}
                </Typography>
              </Stack>
              <Stack direction="column" alignItems="end" spacing={1}>
                <Typography
                  style={{
                    fontFamily: 'sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '18px',
                    color: '#FFFFFF',
                  }}
                  component="text"
                >
                  {`${localStorage.getItem(FULLNAME_NAME)}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <Divider />
      </Stack>
    </div>
  );
};
