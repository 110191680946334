import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import CreateIcon from '@mui/icons-material/Create';
import BackupIcon from '@mui/icons-material/Backup';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
} from '@mui/material';
import {
  getDefaultAlertProps,
  getSelectedLng,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  LANGUAGE_RUS,
  MAIN_COLOR_STRING,
  PAGE_SIZE_DEVICES,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { FactoryBodyHeaderDiv } from '../body-header/FactoryBodyHeaderDiv';
import { ServerStateDto } from '../../dto/ServerStateDto';
import { apiGetServerState } from '../../service/server-state/apiGetServerState';
import { DialogServerStateIp } from '../dialog/DialogServerStateIp';
import { DialogReserveBaseLocal } from '../dialog/DialogReserveBaseLocal';
import { MyButton } from '../UI/MyButton';
import { apiReserveBaseTest } from '../../service/server-state/apiReserveBaseTest';

export const LocalServerStateDiv = () => {
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [open, setOpenDialog] = useState(false);
  const [openReserve, setOpenReserveDialog] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [serverStateDto, setServerStateDto] = useState<ServerStateDto>({
    id: -1,
    serverId: '',
    name: '',
    organization: '',
    appVer: '',
    baseVer: '',
    dateconnect: -1,
    ipLocal: '',
    note: '',
    password: '',
    resDate: '',
    resState: '',
    dateFrom: -1,
    dateTo: -1,
    connectResult: -1,
    baseResult: -1,
  });
  const [serverStateDtos, setServerStateDtos] = useState<ServerStateDto[]>([]);

  const getMoreInformation = (dto: ServerStateDto) => {
    setServerStateDto(dto);
    setOpenDialog(true);
  };

  const getReserveInfo = (dto: ServerStateDto) => {
    setServerStateDto(dto);
    setOpenReserveDialog(true);
  };

  const getServerState = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetServerState();
    if (apiResult.isSuccess) {
      setServerStateDtos(apiResult.data.serverState);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const reserveBaseTest = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiReserveBaseTest();
    if (apiResult.isSuccess) {
      getServerState();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const handleStartTestReserve = async () => {
    await reserveBaseTest();
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    if (!open) {
      getServerState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const columns: GridColDef[] = [
    {
      field: 'organization',
      headerName: t('headerServerOrganization'),
      renderHeader: () => <strong>{t('headerServerOrganization')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.organization,
    },
    {
      field: 'name',
      headerName: t('headerServerName'),
      renderHeader: () => <strong>{t('headerServerName')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'ipLocal',
      headerName: t('headerServerIpLocal'),
      renderHeader: () => <strong>{t('headerServerIpLocal')}</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.ipLocal,
    },
    {
      field: 'appVer',
      headerName: t('headerServerAppVer'),
      renderHeader: () => <strong>{t('headerServerAppVer')}</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.appVer,
    },
    {
      field: 'baseVer',
      headerName: t('headerServerBaseVer'),
      renderHeader: () => <strong>{t('headerServerBaseVer')}</strong>,
      minWidth: 150,
      editable: false,
      renderCell: (row) => row.row.baseVer,
    },
    {
      field: 'dateconnect',
      headerName: t('headerServerDateconnect'),
      renderHeader: () => <strong>{t('headerServerDateconnect')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => (
        <div
          style={{
            color: row.row.connectResult === 0 ? '#ef5350' : '#009688',
          }}
        >
          {unixToLocal(row.row.dateconnect)}
        </div>
      ),
    },
    {
      field: 'resDate',
      headerName: t('headerServerResDate'),
      renderHeader: () => <strong>{t('headerServerResDate')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => (
        <div
          title={
            row.row.resState === '2'
              ? 'Local server base connect error'
              : undefined
          }
          style={{
            color:
              row.row.resState === 0 ||
              row.row.baseResult === 0 ||
              row.row.resState !== '1'
                ? '#ef5350'
                : '#009688',
          }}
        >
          {unixToLocal(row.row.resDate)}
        </div>
      ),
    },
    {
      field: 'note',
      headerName: t('headerServerNote'),
      renderHeader: () => <strong>{t('headerServerNote')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.note,
    },
    {
      field: 'id',
      type: 'actions',
      width: 70,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipEdit')}
            onClick={() => getMoreInformation(row.row)}
          >
            <CreateIcon
              fontSize="small"
              sx={{
                color: '#9c27b0',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            title={t('tooltipBackup')}
            onClick={() => getReserveInfo(row.row)}
          >
            <BackupIcon
              fontSize="small"
              sx={{
                color: '#e91e63',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <FactoryBodyHeaderDiv title={t('localServerState')} />
        </Stack>
        <Stack width="90%" alignItems="end">
          <MyButton
            width="250px"
            text={t('manualReserve')}
            background={MAIN_COLOR_STRING}
            onClick={handleStartTestReserve}
          />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={serverStateDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
      <DialogServerStateIp
        open={open}
        dto={serverStateDto}
        onClose={() => setOpenDialog(false)}
      />
      <DialogReserveBaseLocal
        open={openReserve}
        dto={serverStateDto}
        onClose={() => setOpenReserveDialog(false)}
      />
    </div>
  );
};
