import React, { FunctionComponent, useEffect, useState } from 'react';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import {
  getDefaultAlertProps,
  getSelectedLng,
  otherToDataGrid,
  unixToLocal,
} from '../../utils/utils';
import { LANGUAGE_RUS } from '../../constant/constants';
import { MyAlert } from '../UI/MyAlert';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { ServerStateDto } from '../../dto/ServerStateDto';
import { ReserveDto } from '../../dto/ReserveDto';
import { apiGetReserveBase } from '../../service/server-state/apiGetReserveBase';

interface Props {
  open: boolean;
  dto: ServerStateDto;
  onClose: () => void;
}

export const DialogReserveBaseLocal: FunctionComponent<Props> = ({
  open,
  dto,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const [baseDtos, setBaseDtos] = useState<ReserveDto[]>([]);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkHelper, setCheckHelper] = useState(false);

  const getBases = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetReserveBase(dto.ipLocal);
    if (apiResult.isSuccess) {
      setBaseDtos(apiResult.data.reserveData.reverse());
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  useEffect(() => {
    if (open) {
      getBases();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    onClose();
    setCheckHelper(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('headerServerResDate'),
      renderHeader: () => <strong>{t('headerServerResDate')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.datetime),
    },
    {
      field: 'position',
      headerName: t('headerServerResPosition'),
      renderHeader: () => <strong>{t('headerServerResPosition')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.position,
    },
    {
      field: 'cnt',
      headerName: t('headerServerResCnt'),
      renderHeader: () => <strong>{t('headerServerResCnt')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.cnt,
    },
    {
      field: 'state',
      headerName: t('headerServerReHash'),
      renderHeader: () => <strong>{t('headerServerReHash')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => row.row.state,
    },
  ];

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <BackDropDiv open={backDrop} />
        <DialogTitle>
          <DialogHeaderDiv
            title={t('headerReserveBaseTitle')}
            onClose={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <Stack paddingTop="20px" spacing={1}>
            <Box
              sx={{
                '& .app-theme-cell': {
                  fontFamily: 'sans-serif',
                },
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  sx={{
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                      py: 1,
                      fontSize: '100px',
                    },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                      py: '5px',
                    },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell':
                      {
                        py: '10px',
                      },
                    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                      {
                        outline: 'none !important',
                      },
                  }}
                  getRowHeight={() => 'auto'}
                  columns={columns}
                  rows={baseDtos}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...otherToDataGrid}
                  pageSize={15}
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                />
              </ThemeProvider>
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
