import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import { Box, createTheme, Stack, ThemeProvider } from '@mui/material';
import {
  descrLog,
  getDefaultAlertProps,
  getSelectedLng,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LANGUAGE_RUS,
  PAGE_SIZE_DEVICES,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { LogDto } from '../../dto/LogDto';
import { IApiResult } from '../../interface/IApiResult';
import { apiGetLogs } from '../../service/log/apiGetLogs';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';

export const LogDiv = () => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [logDtos, setLogDtos] = useState<LogDto[]>([]);

  const getLogs = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetLogs();
    if (apiResult.isSuccess) {
      setLogDtos(apiResult.data.logs);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  const columns: GridColDef[] = [
    {
      field: 'datetime',
      headerName: t('datetime'),
      renderHeader: () => <strong>{t('datetime')}</strong>,
      minWidth: 200,
      editable: false,
      renderCell: (row) => unixToLocal(row.row.datetime),
    },
    {
      field: 'name',
      headerName: t('loginName'),
      renderHeader: () => <strong>{t('loginName')}</strong>,
      minWidth: 250,
      editable: false,
      renderCell: (row) => row.row.name,
    },
    {
      field: 'action',
      headerName: t('action'),
      renderHeader: () => <strong>{t('action')}</strong>,
      flex: 1,
      minWidth: 200,
      editable: false,
      renderCell: (row) => descrLog(row.row.action),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  useEffect(() => {
    getLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('flowLog')} />
        </Stack>
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={logDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
    </div>
  );
};
