import React, { FunctionComponent, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';
import { IAlertProps } from '../../interface/IAlertProps';
import { getDefaultAlertProps, unixToLocal } from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { DialogHeaderDiv } from '../dialog-header/DialogHeaderDiv';
import { DeviceLicenseDto } from '../../dto/DeviceLicenseDto';
import { DialogItemInfo } from './DialogItemInfo';

interface Props {
  open: boolean;
  dto: DeviceLicenseDto;
  onClose: () => void;
}

export const DialogDeviceLicense: FunctionComponent<Props> = ({
  open,
  dto,
  onClose,
}) => {
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );

  const handleClose = () => {
    onClose();
  };

  const messageArray = dto.message.split('##,##');

  return (
    <div>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: 'none' }}
        PaperProps={{
          sx: { backgroundColor: '#e7ebee', height: '100%', width: '100%' },
        }}
      >
        <MyAlert
          message={alertProps.message}
          severity={alertProps.severity}
          onClose={() => setAlertProps({ ...alertProps, message: '' })}
        />
        <DialogTitle>
          <DialogHeaderDiv
            title={`Заводской номер ${
              dto.deviceMum
            }. Дата активации ${unixToLocal(dto.datetime.toString())}`}
            onClose={handleClose}
          />
        </DialogTitle>

        <DialogContent>
          <Stack direction="column" spacing={1}>
            {messageArray.map((item) => (
              <DialogItemInfo key={item} stage={item} />
            ))}
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};
