import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColDef, ruRU } from '@mui/x-data-grid';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import OnDeviceTrainingIcon from '@mui/icons-material/OnDeviceTraining';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  createTheme,
  IconButton,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ArchiveIcon from '@mui/icons-material/Archive';
import {
  formatDateDepas,
  formatDescrDepasivate,
  formatDescrDeviceNum,
  formatDescrGprs,
  formatDescrTimeLeft,
  fwVers,
  getDefaultAlertProps,
  getSelectedLng,
  getViewType,
  roundValue,
  unixToLocal,
} from '../../utils/utils';
import { MyAlert } from '../UI/MyAlert';
import { IAlertProps } from '../../interface/IAlertProps';
import { BodyHeaderDiv } from '../body-header/BodyHeaderDiv';
import {
  LANGUAGE_RUS,
  MOBILE_VIEW,
  PAGE_SIZE_DEVICES,
  PC_VIEW,
  ROUND_BATTERY,
  ROUND_TEMP_PROC,
  ROUND_VOLT,
  START_PAGE_SIZE_DEVICES,
} from '../../constant/constants';
import { BackDropDiv } from '../backdrop/BackDropDiv';
import { DialogConfirmation } from '../confirmation/DialogConfirmation';
import { IApiResult } from '../../interface/IApiResult';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { apiDeleteDevice } from '../../service/service/apiDeleteDevice';
import { apiMoveToArchive } from '../../service/service/apiMoveToArchive';
import { ServiceListDto } from '../../dto/ServiceListDto';
import { apiGetServices } from '../../service/service/apiGetDevices';
import { apiUpdate } from '../../service/service/apiUpdate';
import { apiGetActualVers } from '../../service/localbase/apiGetActualVers';
import { ActualVersDto } from '../../dto/ActualVersDto';
import { apiResetArchive } from '../../service/service/apiResetArchive';
import { apiUpdateBoot } from '../../service/service/apiUpdateBoot';

export const ServiceDiv = () => {
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false);
  const [pageSize, setPageSize] = React.useState(START_PAGE_SIZE_DEVICES);
  const { t } = useTranslation();
  const [backDrop, setBackDrop] = useState(false);
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [actualVersDto, setActualVersDto] = useState<ActualVersDto>();
  const [deviceDto, setDeviceDto] = useState<ServiceListDto>();
  const [deviceDtos, setDeviceDtos] = useState<ServiceListDto[]>([]);

  const getDevices = async () => {
    setBackDrop(true);
    const apiResult: IApiResult = await apiGetServices();
    if (apiResult.isSuccess) {
      setDeviceDtos(apiResult.data.devices);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setBackDrop(false);
  };

  const getActualVers = async () => {
    const apiResult: IApiResult = await apiGetActualVers();
    if (apiResult.isSuccess) {
      setActualVersDto(apiResult.data);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const deleteDevice = async (confirm: boolean) => {
    if (!confirm) {
      setOpenDialogConfirmation(false);
      return;
    }
    const apiResult: IApiResult = await apiDeleteDevice(deviceDto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
    setOpenDialogConfirmation(false);
  };

  const moveDeviceToArchive = async (dto: ServiceListDto) => {
    const apiResult: IApiResult = await apiMoveToArchive(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const resetArchive = async (dto: ServiceListDto) => {
    const apiResult: IApiResult = await apiResetArchive(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const updateDevice = async (dto: ServiceListDto) => {
    if (dto.isUpdate === 1) {
      return;
    }
    const apiResult: IApiResult = await apiUpdate(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const updateBoot = async (dto: ServiceListDto) => {
    if (dto.isUpdate === 1) {
      return;
    }
    const apiResult: IApiResult = await apiUpdateBoot(dto);
    if (apiResult.isSuccess) {
      getDevices();
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const confirmDelete = (dto: ServiceListDto) => {
    setDeviceDto(dto);
    setOpenDialogConfirmation(true);
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#1976d2' },
      },
    },
    getSelectedLng() === LANGUAGE_RUS ? ruRU : {}
  );

  useEffect(() => {
    getActualVers();
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'deviceNum',
      headerName: t('headerServiceDeviceNum'),
      renderHeader: () => <strong>{t('headerServiceDeviceNum')}</strong>,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (row) => (
        <div title={formatDescrDeviceNum(row.row.deviceNumDescr)}>
          {row.row.deviceNum}
        </div>
      ),
    },
    {
      field: 'voltBatMain',
      headerName: t('headerServiceVoltBatMain'),
      renderHeader: () => <strong>{t('headerServiceVoltBatMain')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => roundValue(row.row.voltBatMain, ROUND_VOLT, true),
    },
    {
      field: 'voltBatReserv',
      headerName: t('headerServiceVoltBatReserv'),
      renderHeader: () => <strong>{t('headerServiceVoltBatReserv')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => roundValue(row.row.voltBatReserv, ROUND_VOLT, true),
    },
    {
      field: 'voltProc',
      headerName: t('headerServiceVoltProc'),
      renderHeader: () => <strong>{t('headerServiceVoltProc')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => roundValue(row.row.voltProc, ROUND_VOLT, true),
    },
    {
      field: 'tempProc',
      headerName: t('headerServiceTempProc'),
      renderHeader: () => <strong>{t('headerServiceTempProc')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => roundValue(row.row.tempProc, ROUND_TEMP_PROC, true),
    },
    {
      field: 'gprsSendCnt',
      headerName: t('headerServiceGprsSendCnt'),
      renderHeader: () => <strong>{t('headerServiceGprsSendCnt')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => (
        <div title={formatDescrGprs(row.row.gprsSendCntDescr)}>
          {row.row.gprsSendCnt}
        </div>
      ),
    },
    {
      field: 'signalLevel',
      headerName: t('headerServiveSignalLevel'),
      renderHeader: () => <strong>{t('headerServiveSignalLevel')}</strong>,
      minWidth: 50,
      editable: false,
      renderCell: (row) => row.row.signalLevel,
    },
    {
      field: 'restartCount',
      headerName: t('headerServiveRestartCount'),
      renderHeader: () => <strong>{t('headerServiveRestartCount')}</strong>,
      minWidth: 80,
      editable: false,
      renderCell: (row) => row.row.restartCount,
    },
    {
      field: 'timeLeft',
      headerName: t('headerServiceTimeLeft'),
      renderHeader: () => <strong>{t('headerServiceTimeLeft')}</strong>,
      minWidth: 50,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => (
        <div title={formatDescrTimeLeft(row.row.timeLeftDescr)}>
          {roundValue(row.row.timeLeft, ROUND_BATTERY, true)}
        </div>
      ),
    },
    {
      field: 'batDepDate',
      headerName: t('headerServiceBatDepDate'),
      renderHeader: () => <strong>{t('headerServiceBatDepDate')}</strong>,
      minWidth: 110,
      editable: false,
      hide: getViewType() !== PC_VIEW,
      renderCell: (row) => (
        <div
          title={
            row.row.batDepDate === '0'
              ? t('depStatsu0')
              : formatDescrDepasivate(row.row.batDepDateDescr)
          }
        >
          {formatDateDepas(row.row.batDepDate)}
        </div>
      ),
    },
    {
      field: 'fwVers',
      headerName: t('headerServiveFwVer'),
      renderHeader: () => <strong>{t('headerServiveFwVer')}</strong>,
      minWidth: 110,
      editable: false,
      renderCell: (row) => fwVers(row.row.fwVers),
    },
    {
      field: 'bootVers',
      headerName: t('headerServiveBootVer'),
      renderHeader: () => <strong>{t('headerServiveBootVer')}</strong>,
      minWidth: 100,
      hide: getViewType() !== PC_VIEW,
      editable: false,
      renderCell: (row) => fwVers(row.row.bootVers),
    },
    {
      field: 'boardVers',
      headerName: t('headerServiveBoardVer'),
      renderHeader: () => <strong>{t('headerServiveBoardVer')}</strong>,
      minWidth: 50,
      hide: getViewType() !== PC_VIEW,
      editable: false,
      renderCell: (row) => row.row.boardVers,
    },
    {
      field: 'dateCon',
      headerName: t('connect'),
      renderHeader: () => <strong>{t('connect')}</strong>,
      minWidth: 160,
      editable: false,
      hide: getViewType() === MOBILE_VIEW,
      renderCell: (row) => (
        <div style={{ color: row.row.isConnect === 1 ? '#ef5350' : undefined }}>
          {unixToLocal(row.row.dateCon)}
        </div>
      ),
    },
    {
      field: 'id',
      type: 'actions',
      width: 175,
      headerName: '',
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Stack direction="row" width="100%" justifyContent="center">
          <IconButton
            size="small"
            title={t('tooltipResetArchive')}
            onClick={() => resetArchive(row.row)}
          >
            <DateRangeIcon
              fontSize="small"
              sx={{
                color: '#03a9f4',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            title={t('tooltipMoveToArch')}
            onClick={() => moveDeviceToArchive(row.row)}
          >
            <ArchiveIcon
              fontSize="small"
              sx={{
                color: row.row.isArchive === 1 ? 'gray' : '#9ccc65',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={
              row.row.isUpdate === 1 ||
              row.row.isUpdateBoot === 1 ||
              actualVersDto?.fwVers === row.row.fwVers ||
              actualVersDto?.fwVers === '..'
            }
            title={t('tooltipUpdate')}
            onClick={() => updateDevice(row.row)}
          >
            <SystemUpdateIcon
              fontSize="small"
              sx={{
                color:
                  row.row.isUpdate === 1 ||
                  row.row.isUpdateBoot === 1 ||
                  actualVersDto?.fwVers === row.row.fwVers ||
                  actualVersDto?.fwVers === '..'
                    ? 'gray'
                    : '#ab47bc',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            disabled={
              row.row.isUpdate === 1 ||
              row.row.isUpdateBoot === 1 ||
              actualVersDto?.bootVers === row.row.bootVers ||
              actualVersDto?.bootVers === '..'
            }
            title={t('tooltipUpdateBoot')}
            onClick={() => updateBoot(row.row)}
          >
            <OnDeviceTrainingIcon
              fontSize="small"
              sx={{
                color:
                  row.row.isUpdate === 1 ||
                  row.row.isUpdateBoot === 1 ||
                  actualVersDto?.bootVers === row.row.bootVers ||
                  actualVersDto?.bootVers === '..'
                    ? 'gray'
                    : '#ff9800',
              }}
            />
          </IconButton>
          <IconButton
            size="small"
            title={t('tooltipDelete')}
            onClick={() => confirmDelete(row.row)}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{
                color: 'red',
              }}
            />
          </IconButton>
        </Stack>
      ),
    },
  ];

  const other = {
    autoHeight: true,
    showCellRightBorder: true,
    showColumnRightBorder: true,
  };

  return (
    <div>
      <BackDropDiv open={backDrop} />
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <Stack width="100%" alignItems="center" marginBottom="20px" spacing={2}>
        <Stack width="90%">
          <BodyHeaderDiv title={t('service')} />
        </Stack>
        {actualVersDto?.fwVers !== '..' && (
          <Stack width="90%">
            <Typography
              style={{
                fontFamily: 'sans-serif',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '18px',
                color: '#000000',
                textAlign: 'left',
                letterSpacing: 1,
              }}
              component="text"
            >
              {t('headerServiveActualPoVer')} {t('headerServiveActualFwVer')}{' '}
              {fwVers(actualVersDto?.fwVers ?? '')}
              {', '}
              {t('headerServiveActualBootVer')}{' '}
              {fwVers(actualVersDto?.bootVers ?? '')}
            </Typography>
          </Stack>
        )}
        <Stack width="90%" spacing={1}>
          <Box
            sx={{
              '& .app-theme-cell': {
                fontFamily: 'sans-serif',
              },
            }}
          >
            <ThemeProvider theme={theme}>
              <DataGrid
                sx={{
                  '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
                    py: 1,
                    fontSize: '100px',
                  },
                  '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
                    py: '5px',
                  },
                  '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
                    py: '10px',
                  },
                  '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within':
                    {
                      outline: 'none !important',
                    },
                }}
                getRowHeight={() => 'auto'}
                columns={columns}
                rows={deviceDtos}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...other}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={PAGE_SIZE_DEVICES}
                disableSelectionOnClick
                experimentalFeatures={{ newEditingApi: true }}
              />
            </ThemeProvider>
          </Box>
        </Stack>
      </Stack>
      <DialogConfirmation
        open={openDialogConfirmation}
        message={t('confirmDeleteFlowmeter')}
        onClose={deleteDevice}
      />
    </div>
  );
};
